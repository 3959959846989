import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockText from '../components/block-text'
import { ProjectContent } from '../components/projectContent'
import { ProjectFeatureImage } from '../components/projectFeatureImage'

export default function Project(props) {
  const { data, pageContext } = props
  const { next, previous, node } = pageContext
  const project = data.sanityProject
  // image_feature cannot return more than one entry
  const featuredImages = project.image_feature[0]

  const projects = data.allSanityProject.nodes;

  const nextProjectSlug = next?.slug?.current;
  const firstProjectSlug = projects[0]?.slug?.current;


  const nextProjectLink = nextProjectSlug ?
    <Link className="next" to={`/projects/${nextProjectSlug}`}>
      Next project
    </Link> :
    <Link className="next" to={`/projects/${firstProjectSlug}`}>
      Next project
    </Link>;

  const prevProject = (
    <Link className="previous" to={`/projects`}>
      Back to Projects
    </Link>
  );


  return (
    <Layout>
      <SEO title={project.title} />
      <div className="project container-theme">
        <section className="project-feature container-fluid">
          <div className="project-feature-detail row">
            <div className="col-8 col-sm-8 col-md-8 col-lg-3 col-xl-3">
              <h1 className="title text-std">{project.title}</h1>
              <h2 className="type text-std">{project.type}</h2>
            </div>
            <h1 className="headline col-8 col-sm-8 col-md-8 col-lg-4 col-xl-4">
              {project.headline}
            </h1>
          </div>
          {featuredImages && <ProjectFeatureImage feature={featuredImages} />}
          <div className="project-feature-description row">
            <div className="description text-wrap col-4 offset-3 col-6-mob offset-0-mob">
              {!project.hideDescription && (
                <BlockText blocks={project._rawDescription} />
              )}
            </div>
          </div>
        </section>
        <section className={`project-content container-fluid`}>
          {project.content.map((row: unknown, i: number) => {
            return <ProjectContent content={row} key={i} />
          })}
        </section>
        <nav className="project-navigation container-fluid">
          <div className="row">
              <div className="col">{prevProject}</div>
              <div className="col">{nextProjectLink}</div>
          </div>
        </nav>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allSanityProject {
      nodes {
        slug {
          current
        }
        id
      }
    }
    sanityProject(id: { eq: $id }) {
      id
      title
      type
      headline
      hideDescription
      _rawDescription
      image_feature {
        ... on SanityDoubleImage {
          largeImage {
            alt
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          smallImage {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityFigure {
          alt
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
      }
      content {
        ... on SanityProjectImageFull {
          _type
          image {
            alt
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityProjectSmallImage {
          _type
          align
          img {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityProjectMediumImage {
          _type
          align
          img {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityProjectText {
          _type
          _rawText
        }
      }
      slug {
        current
      }
    }
  }
`

import React, { useEffect, useState } from 'react'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'

enum FeatureImageType {
  LAYOUT1 = 'SanityDoubleImage',
  LAYOUT2 = 'SanityFigure',
}

interface DoubleImage {
  __typename: FeatureImageType
  largeImage: {
    alt: string
    asset: {
      fluid: GatsbyImageFluidProps
    }
  }
  smallImage: {
    alt: string
    asset: {
      fluid: GatsbyImageFluidProps
    }
  }
}

interface FullImage {
  __typename: FeatureImageType
  alt: string
  asset: GatsbyImageFluidProps
}
interface ProjectFeatureImageProps {
  feature: DoubleImage | FullImage
}

export const ProjectFeatureImage = ({ feature }: ProjectFeatureImageProps) => {
  const type: FeatureImageType = feature.__typename
  const className = type === FeatureImageType.LAYOUT1 ? 'layout-1' : 'layout-2'

  // Hack to stop animations firing when Gatsby
  // Link is clicked and scrollTo is set to 0,0
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const doubleImage = ({ smallImage, largeImage }) => {
    return (
      <>
        <div
          className="image-wrap col"
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <Img
            fluid={smallImage.asset.fluid}
            alt={smallImage.alt}
            backgroundColor="#BDB9B2"
          />
        </div>
        <div
          className="image-wrap col"
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <Img fluid={largeImage.asset.fluid} alt={largeImage.alt} />
        </div>
      </>
    )
  }

  const fullImage = image => {
    return (
      <div
        className="image-wrap col"
        data-sal="fade"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <Img
          fluid={{ ...image.asset.fluid }}
          alt={image.alt}
          backgroundColor="#BDB9B2"
        />
      </div>
    )
  }

  return (
    <div
      className={`project-feature-image ${className} row`}
      style={{ display: loaded ? 'flex' : 'none' }}
    >
      {type === FeatureImageType.LAYOUT1
        ? doubleImage(feature)
        : fullImage(feature)}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import BlockText from '../components/block-text'

enum ContentType {
  TEXT = 'projectText',
  IMAGE = 'projectImageFull',
  IMAGE_SMALL = 'projectSmallImage',
  IMAGE_MEDIUM = 'projectMediumImage',
}

export const ProjectContent = ({ content }) => {
  // Hack to stop animations firing when Gatsby
  // Link is clicked and scrollTo is set to 0,0
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const className = (type: unknown) => {
    if (type === ContentType.TEXT) {
      return 'text'
    }
    if (type === ContentType.IMAGE) {
      return 'full-img'
    }

    if (type === ContentType.IMAGE_SMALL) {
      return 'small-img'
    }

    if (type === ContentType.IMAGE_MEDIUM) {
      return 'medium-img'
    }
  }

  return (
    <div
      className={`project-content-${className(content._type)} row`}
      style={{ display: loaded ? 'block' : 'none' }}
    >
      {content._type === ContentType.TEXT && (
        <BlockText
          className="text-wrap col-4 offset-3 col-6-mob offset-0-mob"
          blocks={content._rawText}
        />
      )}
      {content._type === ContentType.IMAGE && content.image && (
        <div
          className="image-wrap col-8"
          data-sal="fade"
          data-sal-delay="00"
          data-sal-easing="ease-in-out"
          data-sal-duration="700"
        >
          <Img
            fluid={{ ...content.image.asset.fluid, aspectRatio: 16 / 9 }}
            alt={content.image.alt}
          />
        </div>
      )}
      {content._type === ContentType.IMAGE_SMALL && content.img && (
        <>
          <div
            className={`image-wrap col-4-mob col-3 ${content.align ? 'offset-5' : ''}`}
            data-sal="fade"
            data-sal-delay="00"
            data-sal-easing="ease-in-out"
            data-sal-duration="700"
          >
            <Img fluid={content.img.asset.fluid} alt={content.img.alt} />
          </div>
        </>
      )}
      {content._type === ContentType.IMAGE_MEDIUM && content.img && (
        <>
          <div
            className={`image-wrap col-5-mob col-5 ${content.align ? '' : 'offset-3'}`}
            data-sal="fade"
            data-sal-delay="00"
            data-sal-easing="ease-in-out"
            data-sal-duration="700"
          >
            <Img fluid={content.img.asset.fluid} alt={content.img.alt} />
          </div>
        </>
      )}
    </div>
  )
}
